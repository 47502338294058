import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import styled from 'styled-components';
import { fonts, ProductDescription, ProductPrice, breakpointsDown } from '../styling/constants';

import './productPreview.scss';
import { useIntl } from 'react-intl';

const ProductImage = styled(Img)`
  border: 10px solid white;
  margin-bottom: 1rem;
  width: 100% !important;
  height: 245px !important;
  object-fit: cover !important;
  box-sizing: border-box;
  max-width: 400px;

  @media ${breakpointsDown.tablet} {
    height: 190px !important;
  }
`

const LinkWrapper = styled(Link)`
  width: 100%;
`

const ProductName = styled.h4`
  margin: 0.8rem 0 0.3rem 0 !important;
`

const ProductPreview = ({ product, locale }) => {
  const intl = useIntl()

  return (
  <div className="product-preview">
    <LinkWrapper to={`${intl.locale !== 'sv' ? '/' + intl.locale : ''}/products/${product.category.title.title.toLowerCase()}/${product.slug}`}>
      <ProductImage className="product-image" fixed={product.image[0].fixed} />
      <ProductDescription>{ product.productDescription.productDescription }</ProductDescription>
      <ProductName>{ product.productName.productName }</ProductName>
      <ProductPrice>{ `${product.price} kr` }</ProductPrice>
    </LinkWrapper>
  </div>
  )
}

export default ProductPreview
