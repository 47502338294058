import React from 'react'
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import Button from './button';
import './productShowcase.scss'
import { colors } from '../styling/constants';

import ProductPreview from './productPreview'
import { injectIntl } from 'react-intl';

const ProductsButton = styled.div`
  color: ${colors.darkGray};
  text-align: left;
  margin-top: 3rem;
  margin-left: 3%;
`

const Title = styled.h3`
  margin-bottom: 3rem;
  color: ${colors.darkGray};
`

const ProductShowcase = ({title, products, intl }) => (
  <div className="product-showcase-wrapper">
    { title && <Title>{ title }</Title> }
    <div className="product-showcase">
      { products.map(({ node }) =>
        <ProductPreview product={node} key={node.id} />
      ) }
    </div>
    <ProductsButton>
      <Button color="dark" text={intl.formatMessage({id: "all-products"})} link={`${intl.locale !== 'sv' ? '/' + intl.locale : ''}/products`} />
    </ProductsButton>
  </div>
)

export default injectIntl(ProductShowcase)
