import styled from 'styled-components';

const colors = {
  lightGray: '#ededed',
  darkGray: '#3A3F3F',
  gold: '#957655',
};

const spacings = {
  vertical: '140px',
  horizontal: '10vw',
  horizontalMobile: '5vw',
};

const breakpointsDown = {
  mobile: '(max-width: 420px)',
  middle: '(max-width: 580px)',
  tablet: '(max-width: 740px)',
  desktop: '(max-width: 980px)',
  wide: '(max-width: 1300px)',
};

const fonts = {
  clearface: '\'ITC-Clearface-STD\', serif',
  apercu: '\'apercu\', sans-serif;',
  hanken: '\'hanken_groteskbold\', sans-serif',
};

const ProductDescription = styled.div`
  font-family: ${fonts.hanken};
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${colors.gold};
`;

const ProductPrice = styled.div`
  font-size: 1.5rem;

  @media ${breakpointsDown.tablet} {
    font-size: 1.2rem;
  }
`

export { colors, spacings, fonts, breakpointsDown, ProductDescription, ProductPrice };
