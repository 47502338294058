import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../button';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image'
import { colors, spacings, breakpointsDown, fonts, ProductDescription, ProductPrice } from '../../styling/constants';

const HeroStyling = `
  width: 100vw;
  min-height: 500px;
  margin-top: ${spacings.vertical};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 12rem ${spacings.horizontal};
  box-sizing: border-box;

  @media ${breakpointsDown.tablet} {
    min-height: 300px;
    padding: 6rem ${spacings.horizontalMobile};
  }
`

const GatsbyHeroImage = styled(BackgroundImage)`
  ${HeroStyling}
  &:after {
    background-position: center;
  }
  &:before {
    background-position: center;
  }
`

const HeroImage = styled.div`
  ${HeroStyling}
  background: url(${(props) => props.imageUrl}) no-repeat center center;
  background-size: cover;
`

const TextWrapper = styled.div`
  background-color: ${colors.lightGray};
  width: 100%;
  padding: 4rem 2rem 2rem 2rem;
  box-sizing: border-box;
  text-align: center;
  color: ${colors.darkGray};
`

const ImageHeadline = ({gatsbyImage, image, title, body}) => (
  <>
    { gatsbyImage && (
      <GatsbyHeroImage Tag="div" style={{backgroundPosition: ''}} fluid={ gatsbyImage }>
        { title && (
          <TextWrapper>
            {title && <h3>{title}</h3> }
            {body && <p>{body}</p>}
          </TextWrapper>
        )}
      </GatsbyHeroImage>
    )}
    { image && (
      <HeroImage imageUrl={image}>
        <TextWrapper>
          {title && <h3>{title}</h3> }
          {body && <p>{body}</p>}
        </TextWrapper>
      </HeroImage>
    )}
  </>
);

export default ImageHeadline;
