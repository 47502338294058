import React, { Component, useState, useContext, useEffect } from 'react'
import './productDetails.scss'
import Button from '../button'
import ImageCarousel from './carousel'
import Context from "../Context"
import firebase from 'gatsby-plugin-firebase'
import { FormattedMessage } from 'react-intl'
import { AnimatePresence } from 'framer-motion'
import SizeGuide from './SizeGuide'
import { getViewedItem } from '../../utilities/firebaseAnalytics'

const ProductDetails = ({items, name, price, contentful_id, images, description, sizeGuideData}) => {
  const { data } = useContext(Context);

  const [skus, setSkus] = useState(items.sort((a, b) => a.order - b.order))
  const [selectedSku, setSelectedSku] = useState(skus[0].sku)
  const [sizeGuideOpen, setSizeGuideOpen] = useState(false)

  const handleChangedSelect = (e) => {
    setSelectedSku(e.target.value)
  }

  useEffect(() => {
    firebase.analytics().logEvent(firebase.analytics.EventName.VIEW_ITEM, getViewedItem(name, price))
  }, [])

  const getCartItem = () => {
    return {
      sku: selectedSku,
      name: name,
      price: price,
      id: contentful_id,
      image: images[0],
      size: items.find((e) => { return e.sku === selectedSku }).size
    }
  }

  const getSortedCartItems = () => {
    const sortedArray = [].concat(data.cart)
    var cart = {};
    sortedArray.forEach((item, index) => {
      cart[item.sku] = {
        amount: cart[item.sku] ? cart[item.sku].amount + 1 : 1,
        name: item.name,
        size: item.size,
        price: item.price
      };
    });

    return cart
  }

  const canAddItem = (sku) => {
    const skusInCart = getSortedCartItems()[sku];
    if (typeof skusInCart === 'undefined') {
      return true
    } else {
      const numberOfSkusInStock = items.filter(item => item.sku === sku)[0].quantity
      if (skusInCart.amount + 1 <= numberOfSkusInStock) {
        return true
      } else {
        return false
      }
    }
  }

  const sizeOptions = [].concat(items)
      .sort((a, b) => a.order - b.order)
      .map((item, i) => {
        if (item.quantity > 0) {
          return <option value={item.sku} key={i}>{ item.size }</option>
        } else {
          return <option value={item.sku} key={i} disabled>{ item.size }</option>
        }
      });

  const onClose = (e) => {
    e.preventDefault()
    setSizeGuideOpen(false)
  }

  return (
    <div className="product-details">
      <div>
        <div>
          {typeof window !== 'undefined' && <ImageCarousel images={ images } />}
        </div>
      </div>

      <div className="details-text">
        <h3>{ name }</h3>
        <div className="price">{ price } kr</div>
        <p className="description">{ description }</p>

        <div className="add-to-cart">
          <select value={selectedSku} onChange={(e) => handleChangedSelect(e)} className="selector">
              { sizeOptions }
          </select>
          <button className="size-guide-link" onClick={(e) => setSizeGuideOpen(true)}>
            <FormattedMessage id="size-guide" />
          </button>
          <AnimatePresence>
            { sizeGuideOpen && <SizeGuide data={sizeGuideData} selected={contentful_id} onClose={onClose} /> }
          </AnimatePresence>
        </div>
        <Context.Consumer>
          {({ data, set }) => (
            <button onClick={
              () => {
                if (canAddItem(getCartItem().sku)) {
                  firebase.analytics().logEvent(firebase.analytics.EventName.ADD_TO_CART, getViewedItem(name, price, getCartItem().sku))
                  set(
                    {
                      cart: [...data.cart, getCartItem()],
                      cartOpen: true,
                    },
                  );
                } else {
                  alert('Cannot add to cart; the item is out of stock');
                }
              }
            }>
              <FormattedMessage id="add-to-cart">
                { (translation) => <Button color="dark" text={translation} /> }
              </FormattedMessage>
            </button>
          )}
        </Context.Consumer>
      </div>
    </div>
  )

}

/*class ProductDetails extends Component {

  constructor(props) {
    super(props);

    const sortedArray = [].concat(props.items)
      .sort((a, b) => a.order - b.order);
    
    this.state = {value: sortedArray[0].sku};
    this.handleChangedSelect = this.handleChangedSelect.bind(this);
  }

  handleChangedSelect(event) {
    this.setState({value: event.target.value});
  }

  getCartItem() {
    const itemToAdd = {
      sku: this.state.value,
      name: this.props.name,
      price: this.props.price,
      id: this.props.contentful_id,
      image: this.props.images[0],
      size: this.props.items.find((e) => { return e.sku === this.state.value }).size
    }

    return itemToAdd
  }

  getSortedCartItems() {
    const sortedArray = [].concat(this.context.data.cart)
    var cart = {};
    sortedArray.forEach(function (item, index) {
      cart[item.sku] = {
        amount: cart[item.sku] ? cart[item.sku].amount + 1 : 1,
        name: item.name,
        size: item.size,
        price: item.price
      };
    });

    return cart;
  }

  canAddItem(sku) {
    const skusInCart = this.getSortedCartItems()[sku];
    if (typeof skusInCart === 'undefined') {
      return true
    } else {
      const numberOfSkusInStock = this.props.items.filter(item => item.sku === sku)[0].quantity
      if (skusInCart.amount + 1 <= numberOfSkusInStock) {
        return true
      } else {
        return false
      }
    }
  }

  render() {
    const sizeOptions = [].concat(this.props.items)
      .sort((a, b) => a.order - b.order)
      .map((item, i) => {
        if (item.quantity > 0) {
          return <option value={item.sku} key={i}>{ item.size }</option>
        } else {
          return <option value={item.sku} key={i} disabled>{ item.size }</option>
        }
      });

    return (
      <div className="product-details">
        <div>
          <div>
            {typeof window !== 'undefined' && <ImageCarousel images={ this.props.images } />}
          </div>
        </div>

        <div className="details-text">
          <h3>{ this.props.name }</h3>
          <div className="price">{ this.props.price } kr</div>
          <p className="description">{ this.props.description }</p>

          <div className="add-to-cart">
            <select value={this.state.value} onChange={this.handleChangedSelect} className="selector">
                { sizeOptions }
            </select>
            <a className="size-guide-link" href="hej">
              <FormattedMessage id="size-guide" />
            </a>
            <SizeGuide data={this.props.sizeGuideData} selected={this.props.contentful_id} />
          </div>
          <Context.Consumer>
            {({ data, set }) => (
              <button onClick={
                () => { 
                  if (this.canAddItem(this.getCartItem().sku)) {
                    set(
                      {
                        cart: [...data.cart, this.getCartItem()],
                        cartOpen: true
                      }
                    )
                  } else {
                    alert('Cannot add to cart; the item is out of stock')
                  }
                }
              }>
                <FormattedMessage id="add-to-cart">
                  { translation => <Button color="dark" text={translation} /> }
                </FormattedMessage>
              </button>
            )}
          </Context.Consumer>
        </div>
      </div>
    )
  }
}

ProductDetails.contextType = Context;*/

export default ProductDetails
