import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'

import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager'
import { FormattedMessage } from 'react-intl'
import StoryShowcase from '../components/home/storyShowcase'
import styled from 'styled-components'
import { colors } from '../styling/constants'

import visionText from '../images/vision2022.svg'

const MarginTitle = styled.div`
  h4 {
    margin-top: 3rem;
  }
`

const NumbersContainer = styled.div`
  margin-top: 3rem;
`

const NumberItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  color: ${colors.darkGray};
  font-size: 1.4rem;
  opacity: ${(props) => props.active ? 1 : 0.5};

  p {
    margin: 0;
  }
`

const Number = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  border: 1px solid ${colors.darkGray};
  margin-right: 2rem;
`

const ImgText = styled.img`
  z-index: 100;
  height: 2rem;
`

const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Override = styled.div`
  position: relative;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Sustainability = ({data, pageContext: { locale }}) => (
  <LocaleManager locale={locale}>
    <FormattedMessage id="sustainability">
      {(translation) => <SEO title={translation} /> }
    </FormattedMessage>
    <div className="content extra-top markdown-content">
      <div>
        <h3>
          <FormattedMessage id="sustainability" />
        </h3>
        <ReactMarkdown source={data.brandingOne.text.text} />
      </div>
      <Override className="override-margin">
          <Image style={{height: '450px', width: '100%'}} fluid={data.storyImage.childImageSharp.fluid} />
          <ImgText src={visionText} alt="Vision 2022" />
      </Override>
      <MarginTitle>
        <ReactMarkdown source={data.brandingTwo.text.text} />
        <NumbersContainer>
          <NumberItem active>
            <Number>1</Number>
            <p>
              <FormattedMessage id="sustainability.points.1" />
            </p>
          </NumberItem>
          <NumberItem active>
            <Number>2</Number>
            <p>
              <FormattedMessage id="sustainability.points.2" />
            </p>
          </NumberItem>
          <NumberItem>
            <Number>3</Number>
            <p>
              <FormattedMessage id="sustainability.points.3" />
            </p>
          </NumberItem>
        </NumbersContainer>
      </MarginTitle>
      <StoryShowcase title={data.brandingThree.titleBranding} text={data.brandingThree.text.text} cta={data.brandingThree.cta} />
    </div>
  </LocaleManager>
)

export const query = graphql`
  query($locale: String) {
    storyImage: file(relativePath: { eq: "vision2021bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brandingOne: contentfulBranding(title: { eq: "Sustainability-1" }, node_locale: { eq: $locale } )  {
      text {
        text
      }
    }
    brandingTwo: contentfulBranding(title: { eq: "Sustainability-2" }, node_locale: { eq: $locale } )  {
      text {
        text
      }
    }
    brandingThree: contentfulBranding(title: { eq: "Sustainability-3" }, node_locale: { eq: $locale } )  {
      titleBranding
      cta {
        text
        link
      }
      text {
        text
      }
    }
  }
`;

export default Sustainability
