import React from "react"
import { StoreContext, defaultContextValue } from "./ContextCreator"

class ContextProviderComponent extends React.Component {
  constructor() {
    super()

    this.setData = this.setData.bind(this)
    if (typeof window !== 'undefined') {
      this.state = {
        ...defaultContextValue,
        ...JSON.parse(localStorage.getItem('cart')),
        set: this.setData,
      }
      this.state.data.cartOpen = false
    } else {
      this.state = {
        ...defaultContextValue,
        set: this.setData
      }
    }
  }

  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      localStorage.setItem('cart', JSON.stringify(this.state))
    }
  }

  setData(newData) {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData,
      },
    }))
  }

  render() {
    return <StoreContext.Provider value={this.state}>{this.props.children}</StoreContext.Provider>
  }
}

export { StoreContext as default, ContextProviderComponent }
