import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import ReactMarkdown from 'react-markdown'

import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager'
import { FormattedMessage } from 'react-intl'

const OurStory = ({data, pageContext: { locale }}) => (
  <LocaleManager locale={locale}>
    <FormattedMessage id="story.title">
     {(translation) => <SEO title={translation} />}
    </FormattedMessage>
    <div className="content extra-top markdown-content">
      <div>
        <h3>
          <FormattedMessage id="story.title" />
        </h3>
        <ReactMarkdown source={data.brandingOne.text.text} />
      </div>
      <div className="override-margin">
          <Img style={{height: '450px', width: '100%'}} fluid={data.storyImage.childImageSharp.fluid} />
      </div>
      <p>{data.brandingTwo.text.text}</p>
    </div>
  </LocaleManager>
)

export const query = graphql`
  query($locale: String) {
    storyImage: file(relativePath: { eq: "jamtland.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brandingOne: contentfulBranding(title: { eq: "StoryPage-1" }, node_locale: { eq: $locale } )  {
      text {
        text
      }
    }
    brandingTwo: contentfulBranding(title: { eq: "StoryPage-2" }, node_locale: { eq: $locale } )  {
      text {
        text
      }
    }
  }
`;

export default OurStory
