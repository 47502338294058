import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { AnimatePresence } from 'framer-motion'

import Header from './header'
import Footer from './footer'
import './layout.scss'

import { ContextProviderComponent } from "./Context"
import { CookiePopup } from './CookiePopup'

const Layout = ({ children, locale }) => {
  const [showCookieWarning, setShowCookieWarning] = useState(false)

  useEffect(() => {
    const cookiesOk = JSON.parse(localStorage.getItem('cookies_ok'))
    if (cookiesOk === null) {
      setShowCookieWarning(true)
    } else if (cookiesOk) {
      setShowCookieWarning(false)
    } else {
      setShowCookieWarning(true)
    }
  }, []);

  const handleCookieClick = (e) => {
    e.preventDefault()
    setShowCookieWarning(false)
    localStorage.setItem('cookies_ok', true)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                sv {
                  name
                  link
                }
                en {
                  name
                  link
                }
              }
              footerLinks {
                sv {
                  name
                  link
                }
                en {
                  name
                  link
                }
              }
            }
          }
        }
      `}
      render={data => (
        <ContextProviderComponent>
          <Header siteTitle={data.site.siteMetadata.title} locale={locale} menuLinks={ data.site.siteMetadata.menuLinks[locale] }/>
          <AnimatePresence>
            { showCookieWarning && <CookiePopup locale={locale} clicked={handleCookieClick} /> }
          </AnimatePresence>

          {children}

          <Footer locale={locale} siteTitle={data.site.siteMetadata.title} menuLinks={ data.site.siteMetadata.menuLinks[locale] } footerLinks={ data.site.siteMetadata.footerLinks[locale] } />
        </ContextProviderComponent>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
