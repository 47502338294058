import React, { Component, useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { StoreContext } from "../ContextCreator"
import OrderTable from "./OrderTable"
import styled from 'styled-components';
import { fonts, colors } from '../../styling/constants';
import './kco.scss'
import { FormattedMessage } from 'react-intl'
import { motion, AnimatePresence } from 'framer-motion'
import firebase from 'gatsby-plugin-firebase'

import darkArrow from "../../images/arrow-dark.svg"
import minusIcon from '../../images/minus-icon-dark.svg';
import { getFirebaseCart } from '../../utilities/firebaseAnalytics';

let InnerHTML;

const DiscountInput = styled.input`
  background: none;
  border: none;
  font-size: 1rem;
  font-family: ${fonts.apercu};
  color: ${colors.darkGray};
  padding: 1rem 0.4rem;
  width: 12rem;
  outline: none;
`

const DiscountWrapper = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 25px;
`

const DiscountButton = styled(motion.button)`
  margin-left: 1rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
`

const DiscountError = styled.p`
  color: red;
  margin: 0 0 0 1rem;
  padding: 0;
`

const DiscountShower = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0.6;
`

const RemoveDiscount = styled.img`
  margin-left: 1rem;
  margin-top: 0.1rem;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
`

const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const KCO = ({locale}) => {
  const { data } = useContext(StoreContext);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [localCart, setLocalCart] = useState(data.cart)
  const [showInnerHTML, setShowInnerHTML] = useState(false)
  const [discountCodeInput, setDiscountCodeInput] = useState("")
  const [discount, setDiscount] = useState(typeof window !== 'undefined' ? (JSON.parse(localStorage.getItem('discount_code')) || {}) : {})
  const [discountError, setDiscountError] = useState(false)
  const [loadingDiscount, setLoadingDiscount] = useState(false)
  const [kcoSnippet, setKcoSnippet] = useState("")
  
  const getCartItems = () => {
    const sortedArray = [].concat(data.cart)
    var cart = {};
    sortedArray.forEach(function (item, index) {
      cart[item.sku] = {
        amount: cart[item.sku] ? cart[item.sku].amount + 1 : 1,
        name: item.name,
        size: item.size,
        price: item.price,
        id: item.id
      };
    });

    return cart
  }

  const getKCOData = () => {
    const itemsArray = Object.keys(getCartItems()).map(key => {
      const kcoItem = {
        [key]: {
          "id": getCartItems()[key].id,
          "amount": getCartItems()[key].amount,
        }
      }
      return kcoItem
    })

    const kcoData = {
      "cart": itemsArray,
      "locale": locale,
      "code": discount.code || "",
    }

    return kcoData
  }

  const fetchCheckout = () => {
    setLoading(true)

    axios
      .post(`${process.env.GATSBY_KCO_URL}/createOrder`, getKCOData())
      .then(response => {
        if (response.status === 201) {
          const kcoOrderId = response.data.order_id
          localStorage.setItem('kco_order_id', kcoOrderId)
          const date = new Date()
          localStorage.setItem('kco_expiry', new Date())

          const kcoSnippet = response.data.html_snippet
          setLoading(false)
          setKcoSnippet(kcoSnippet)
        } else {
          setLoading(false)
          setError(true)
          console.log("e1")
        }
    }).catch(error => {
      console.log(error)
      setLoading(false)
      setError(true)
      console.log("e2")
    })
  }

  const updateCheckout = () => {
    const checkoutID = localStorage.getItem('kco_order_id')
    if (!checkoutID) {
      return
    }

    if (kcoSnippet !== "") {
      window._klarnaCheckout(function (api) {
        api.suspend();
      });
    }

    const kcoData = {
      ...getKCOData(),
      "checkout_id": checkoutID
    }

    axios
      .post(`${process.env.GATSBY_KCO_URL}/updateOrder`, kcoData)
      .then(response => {
        if (response.status === 201) {
          window._klarnaCheckout(function (api) {
            api.resume();
          });
        } else {
          console.log(response)
          setLoading(false)
        }
    }).catch(error => {
      console.log(error)
      setLoading(false)
    })
  }

  const getCheckoutFromID = (id) => {
    setLoading(true)

    axios
      .get(`${process.env.GATSBY_KCO_URL}/order`, {
        params: {
          order_id: id
        }
      })
      .then(response => {
        if (response.status === 200) {
          const kcoSnippet = response.data.html_snippet
          setLoading(false)
          setKcoSnippet(kcoSnippet)
          updateCheckout()
        } else {
          fetchCheckout()
        }
    }).catch(error => {
      console.log(error)
      fetchCheckout()
    })
  }

  const getCartTotal = () => {
    const cartItems = getCartItems()
    const total = Object.values(cartItems).reduce((a, b) => a + b.amount * b.price, 0);
    return total;
  }

  useEffect(() => {
    if (data.cart !== localCart) {
      updateCheckout()
      setLocalCart(data.cart)
    }
  }, [data])

  useEffect(() => {
    localStorage.setItem('discount_code', JSON.stringify(discount))
    updateCheckout()
  }, [discount])

  useEffect(() => {
    firebase.analytics().logEvent(firebase.analytics.EventName.BEGIN_CHECKOUT, getFirebaseCart(data.cart, getCartTotal()));

    const kco_id = localStorage.getItem('kco_order_id')
    const kco_timestamp = localStorage.getItem('kco_expiry')
    const kco_date = new Date(kco_timestamp)
    const current_date = new Date()
    var hours = Math.abs(current_date - kco_date) / 36e5;

    if (kco_id && hours <= 47) {
      getCheckoutFromID(kco_id)
    } else {
      fetchCheckout()
    }

    InnerHTML = require("dangerously-set-inner-html").default;
    setShowInnerHTML(true)
  }, []);

  const handleDiscountSubmit = async (e) => {
    e.preventDefault();
    setLoadingDiscount(true)
    setDiscountError(false)

    try {
      const response = await axios
        .get(`${process.env.GATSBY_KCO_URL}/discount`, {
          params: {
            code: discountCodeInput,
          }
        })
      setLoadingDiscount(false)
      if (response.status === 200) {
        setDiscountError(false)
        setDiscount({
          code: discountCodeInput,
          discount: response.data.discount
        })
        setDiscountCodeInput("")
      } else {
        setDiscountError(true)
      }
    } catch (error) {
      setDiscountError(true)
      setLoadingDiscount(false)
    }
  }

  return (
    <div className="checkout-wrapper">
      <h3>
        <FormattedMessage id="checkout.title" />
      </h3>
      <div className="order-checkout-wrapper">
        <OrderTable discount={discount} />
        { !discount.code && !discount.discount && (
          <DiscountWrapper onSubmit={handleDiscountSubmit}>
            <FormattedMessage id="checkout.have-discount">
                  { translation => <DiscountInput type="text" value={discountCodeInput} onChange={(e) => setDiscountCodeInput(e.target.value)} placeholder={translation} /> }
            </FormattedMessage>
            <AnimatePresence>
              {discountCodeInput && !loadingDiscount && (
                <DiscountButton animate={{ x: -10, opacity: 1 }} initial={{opacity: 0}} exit={{ x: 10, opacity: 0 }} type="submit">
                  <img src={darkArrow} alt="Check discount code" />
                </DiscountButton>
              )}
              { discountError && (
                <DiscountError>
                  <FormattedMessage id="checkout.invalid-code" />
                </DiscountError>
              )}
              { loadingDiscount && <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> }
            </AnimatePresence>
          </DiscountWrapper>
        )}
        { discount.code && discount.discount && (
          <DiscountShower>
            <p>
              <FormattedMessage id="checkout.discountcode" /> {discount.code}
            </p>
            <RemoveDiscount src={minusIcon} alt="Remove discount" onClick={() => setDiscount({})} />
          </DiscountShower>
        )}
        <div className="kco">
          { error && <p><FormattedMessage id="checkout.error" /></p> }
          { loading && (
            <LoadingWrapper>
              <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> 
            </LoadingWrapper>
          )}
          { showInnerHTML && <InnerHTML html={kcoSnippet} /> }
        </div>
      </div>
    </div>
  )
}

export default KCO
