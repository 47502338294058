import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../button';
import styled from 'styled-components';
import { colors, breakpointsDown, fonts, ProductDescription, ProductPrice } from '../../styling/constants';

const HeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 150px;
  align-items: center;

  @media ${breakpointsDown.tablet} {
    flex-direction: column;
    margin-top: 100px;
  }
`

const HeroImage = styled(Img)`
  width: 50vw;
  height: 450px;
  @media ${breakpointsDown.tablet} {
    width: 100vw;
    height: 400px;
  }
`;

const HeroContent = styled.div`
  padding-left: 5rem;
  padding-right: 10vw;
  box-sizing: border-box;
  color: ${colors.darkGray};
  width: 50vw;

  @media ${breakpointsDown.tablet} {
    width: 100vw;
    padding: 3rem 5vw;
  }
`

const ButtonWrapper = styled.div`
  color: ${colors.gold};
  margin-top: 2.4rem;
`

const FeaturedProductWrapper = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media ${breakpointsDown.tablet} {
    padding: 0 5vw;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
  }
`

const GoldenLine = styled.div`
  height: 70px;
  width: 1px;
  background-color: ${colors.gold};
  margin: 2.5rem 0;
`

const FeaturedProductImage = styled(Img)`
  border: 10px solid white;
  width: 425px !important;
  height: 300px !important;
  object-fit: contain !important;

  @media ${breakpointsDown.desktop} {
    width: 330px !important;
  }

  @media ${breakpointsDown.tablet} {
    width: 100% !important;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${breakpointsDown.tablet} {
    flex-direction: column;
    width: 100%;
  }
`;

const FeaturedProductInfo = styled.div`
  position: absolute;
  right: -13rem;
  color: ${colors.darkGray};

  @media ${breakpointsDown.tablet} {
    position: relative;
    right: auto;
    align-self: start;
    margin-top: 1.5rem;
  }
`;

const ProductName = styled.h4`
  margin: 1.25rem 0 0.5rem 0;
`

const LinkWrapper = styled(Link)`
  @media ${breakpointsDown.tablet} {
    width: 100%;
  }
`

const HeroImageText = ({locale, titleHeader, featuredProduct }) => (
  <StaticQuery
    query={graphql`
    query {
      heroImage: file(relativePath: { eq: "ebba-hero-2.JPG" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`}
    render={(data) => (
      <>
        <HeroWrapper>
          <HeroImage fluid={data.heroImage.childImageSharp.fluid} />
          <HeroContent>
            <h1>
              {titleHeader.titleBranding}
            </h1>
            <p>
              {titleHeader.text.text}
            </p>
            <ButtonWrapper>
              <Button color="gold" text={titleHeader.cta.text} link={titleHeader.cta.link} />
            </ButtonWrapper>
          </HeroContent>
        </HeroWrapper>
        <FeaturedProductWrapper>
          <GoldenLine />
          <LinkWrapper to={`products/${featuredProduct.category.title.title.toLowerCase()}/${featuredProduct.slug}`}>
            <ImageWrapper>
              <FeaturedProductImage fixed={featuredProduct.image[0].fixed} />
              <FeaturedProductInfo>
                <ProductDescription>
                  {featuredProduct.productDescription.productDescription}
                </ProductDescription>
                <ProductName>{featuredProduct.productName.productName}</ProductName>
                <ProductPrice>{`${featuredProduct.price} kr`}</ProductPrice>
              </FeaturedProductInfo>
            </ImageWrapper>
          </LinkWrapper>
        </FeaturedProductWrapper>
      </>
    )}
  />
);

export default HeroImageText;
