import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Transition } from 'react-spring/renderprops'

import Context from "./Context"

import './header.scss'
import logo from "../images/logo.svg"
import cartImage from "../images/cart.svg"

import Cart from "./cart/cart"
import MobileMenu from "./mobileMenu"
import Hamburger from "./hamburger"
import styled from 'styled-components'
import { colors } from '../styling/constants'

class Header extends Component {
  state = {
    menuOpen: false
  }

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleState = this.toggleState.bind(this);
  }

  toggleState() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }
  
  updateWindowDimensions() {
    if (window.innerWidth >= 740) {
      this.setState({
        menuOpen: false
      })
    }
  }

  render() {
    return (
      <>
        <div className="mobile-menu-button">
          <div onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}>
            <Hamburger isOpen={this.state.menuOpen} />
          </div>
        </div>

        <div className="header">
          <Transition
                    items={this.state.menuOpen}
                    from={{ opacity: 0 }}
                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}>
                    {show => show && (props => <div className="header-bg" style={props}></div>)}
          </Transition>
          
          <Link to={this.props.locale !== 'sv' ? '/' + this.props.locale : '/'} onClick={() => this.setState({ menuOpen: false })}><img className="header-logo" src={logo} alt="Belt of Sweden logo" width="300" /></Link>
          <nav>
            <ul>
            { this.props.menuLinks.map((link, i) =>
              <li key={i}>
                <Link to={ link.link }>{ link.name }</Link>
              </li>
            )}
            </ul>
          </nav>
          <div className="mobile-menu-container">
            <Transition
                    items={this.state.menuOpen}
                    from={{ opacity: 0, height: 0 }}
                    enter={{ opacity: 1, height: 'auto' }}
                    leave={{ opacity: 0, height: 0 }}>
                    {show => show && (props => <div style={props}><MobileMenu toggleMenu={this.toggleState} menuLinks={this.props.menuLinks} /></div>)}
            </Transition>
          </div>
        </div>

        <div className="cart-button">
          <Context.Consumer>
            {({ data, set }) => (
              <button onClick={() => set({ cartOpen: !data.cartOpen })}><img src={cartImage} alt="Cart" width="30" /></button>
            )}
          </Context.Consumer>
        </div>
        <Context.Consumer>
          {({ data, set }) => {
              return(<Transition
                items={data.cartOpen}
                from={{ opacity: 0 }}
                enter={{ opacity: 0.55 }}
                leave={{ opacity: 0 }}>
                {show => show && (props => <div onClick={() => set({ cartOpen: false })} className="content-darkener" style={props}></div>)}
              </Transition>)
          }}
          </Context.Consumer>
        <div className="cart-container">
          <Context.Consumer>
            {({ data }) => {
              return(<Transition
                items={data.cartOpen}
                from={{ opacity: 1, transform: 'translateX(100%)' }}
                enter={{ opacity: 1, transform: 'translateX(0%)' }}
                leave={{ opacity: 1, transform: 'translateX(100%)' }}>
                {show => show && (props => <div style={props}><Cart /></div>)}
              </Transition>)
            }}
          </Context.Consumer>
        </div>
      </>
    )
  }
}

export default Header
