import React from 'react'
import { graphql } from 'gatsby'

import LocaleManager from '../components/LocaleManager'
import SEO from '../components/seo'
import ProductDetails from '../components/product/productDetails'
import ProductMarketing from '../components/product/productMarketing'
import ProductShowcase from '../components/productShowcase'
import ImageHeadline from '../components/home/ImageHeadline'
import { FormattedMessage } from 'react-intl'

const Product = ({ data, pageContext: { locale } }) => (
  <LocaleManager locale={locale}>
    <SEO title={ data.product.productName.productName } keywords={[`gatsby`, `application`, `react`]} />
    <div className="content extra-top">
      <ProductDetails sizeGuideData={data.sizeGuideData.edges} name={ data.product.productName.productName } contentful_id={data.product.contentful_id} price={ data.product.price } description={ data.product.longDescription.longDescription } images={ data.product.image } items={ data.product.items }/>
      <div className="override-margin">
        <ProductMarketing first={ data.marketingOne } second={data.marketingTwo} third={data.marketingThree} />
      </div>
      <FormattedMessage id="product.more-belts">
        { translation => <ProductShowcase products={ data.relatedProducts.edges } title={ translation } /> }
      </FormattedMessage>
    </div>
    <ImageHeadline gatsbyImage={ data.bottomBg.childImageSharp.fluid } />
  </LocaleManager>
)

export const query = graphql`
  query($id: String, $categoryId: String, $locale: String) {
    bottomBg: file(relativePath: { eq: "about-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marketingOne: contentfulBranding(title: { eq: "Product-Shipping" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    marketingTwo: contentfulBranding(title: { eq: "Product-Fit" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    marketingThree: contentfulBranding(title: { eq: "Product-Sustainability" }, node_locale: { eq: $locale } )  {
      titleBranding
      text {
        text
      }
    }
    product: contentfulProduct(id: { eq: $id }) {
      slug
      id
      price
      contentful_id
      category {
        title {
          title
        }
      }
      items {
        size
        quantity
        order
        sku
      }
      image {
        id
        fixed(width: 500) {
          ...GatsbyContentfulFixed
        }
      }
      longDescription {
        longDescription
      }
      detailsMaterial {
        detailsMaterial
      }
      detailsColor {
        detailsColor
      }
      detailsWarranty {
        detailsWarranty
      }
      productName {
        productName
      }
    }
    sizeGuideData: allContentfulCategory(filter: {node_locale: {eq: $locale }}) {
      edges {
        node {
          title {
            title
          }
          product {
            productName {
              productName
            }
            category {
              title {
                title
              }
            }
            contentful_id
            items {
              size
              perfectFit
              minFit
              maxFit
            }
          }
        }
      }
    }
    relatedProducts: allContentfulProduct(limit: 3, filter: { category: {id: {eq: $categoryId } }, id: { ne: $id }, node_locale: { eq: $locale } }) {
      edges {
        node {
          id
          price
          productDescription {
            productDescription
          }
          slug
          category {
            title { title }
          }
          image {
            fixed(width: 350) {
              ...GatsbyContentfulFixed
            }
          }
          productName {
            productName
          }
        }
      }
    }
  }
`;

export default Product
