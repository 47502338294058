import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl';

import LocaleManager from '../components/LocaleManager'
import HeroImageText from '../components/home/HeroImageText'
import ImageHeadline from '../components/home/ImageHeadline'
import ProductShowcase from '../components/productShowcase'
import StoryShowcase from '../components/home/storyShowcase'
import InstagramShowcase from '../components/home/InstagramShowcase'
import SEO from '../components/seo'
import FreeShipping from '../components/home/FreeShipping';
import Layout from '../components/layout';

const IndexPage = ({ data, pageContext: { locale } }) => (
  <LocaleManager locale={locale}>
    <FormattedMessage id="home">
      { translation => <SEO title={translation} keywords={[`gatsby`, `application`, `react`]} /> }
    </FormattedMessage>

    <HeroImageText locale={locale} titleHeader={data.titleHeader} featuredProduct={data.featuredProduct} />
    <FormattedMessage id="spring-outfits">
      { translation => <ImageHeadline gatsbyImage={ data.fallBg.childImageSharp.fluid } title={translation} /> }
    </FormattedMessage>
    <div className="content">
      <ProductShowcase products={ data.products.edges } />
    </div>
    <FreeShipping />
    <div className="content">
      <InstagramShowcase locale={locale} />
    </div>
    <ImageHeadline gatsbyImage={ data.bottomBg.childImageSharp.fluid } />
  </LocaleManager>
)

export const query = graphql`
  query($locale: String) {
    fallBg: file(relativePath: { eq: "fall-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomBg: file(relativePath: { eq: "about-image.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    products: allContentfulProduct(limit: 3, filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          id
          price
          productDescription {
            productDescription
          }
          slug
          category {
            title { title }
          }
          image {
            fixed(width: 350) {
              ...GatsbyContentfulFixed
            }
          }
          productName {
            productName
          }
        }
      }
    }
    titleHeader: contentfulBranding(title: { eq: "Hero" }, node_locale: {eq: $locale} )  {
      titleBranding
      cta {
        text
        link
      }
        text {
        text
      }
    }
    featuredProduct: contentfulProduct(productName: { productName: { eq: "Fröå" } }, node_locale: { eq: $locale }) {
      productName {
        productName
      }
      price
      productDescription {
        productDescription
      }
      slug
      category {
        title { title }
      }
      image {
        id
        fixed(width: 500) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`;

export default IndexPage

