import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager';

const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { url } = node.data.target.fields.file["en-US"];
        return <img style={{maxWidth: 380, margin: '20px 0', width: '100%'}} src={url} />
      }
    }
};

const Subpage = ({ data: {subpage: { title, content: { json }}}, pageContext: { locale } }) => (
  <LocaleManager locale={locale}>
    <SEO title={ title } />
    <div className="content extra-top">
      <div className="markdown-content">
        <h3>{title}</h3>
        { documentToReactComponents(json, options) }
      </div>
    </div>
  </LocaleManager>
)

export const query = graphql`
  query($id: String) {
    subpage: contentfulSubpage(id: { eq: $id }) {
        id
        content {
            json
        }
        title
    }
  }
`;

export default Subpage
