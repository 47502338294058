import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import './storyShowcase.scss'

import Button from '../button'

const StoryShowcase = ({title, text, cta}) => (
  <StaticQuery
    query={graphql`
      query {
        aboutImage: file(relativePath: { eq: "about-founders.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>
      <div className="story-showcase">
        <div className="text-content">
          <h1>{title}</h1>
          <p>{text}</p>
          <div className="special-button"><Button color="gold" text={cta.text} link={cta.link} /></div>
        </div>
        <Img className="about-image" fluid={data.aboutImage.childImageSharp.fluid} />
      </div>
    }
  />
)
export default StoryShowcase

