import React, { Component } from 'react'
import Context from "../Context"
import firebase from 'gatsby-plugin-firebase'

import './cart.scss'

import cartClose from "../../images/cart-close.svg"
import Button from "../button"
import minusIcon from "../../images/minus-icon.svg"
import { injectIntl, FormattedMessage } from 'react-intl'
import { getViewedItem } from '../../utilities/firebaseAnalytics'

class Cart extends Component {
  getCartItems() {
    const sortedArray = [].concat(this.context.data.cart)
    var cart = {};
    sortedArray.forEach(function (item, index) {
      cart[item.sku] = {
        amount: cart[item.sku] ? cart[item.sku].amount + 1 : 1,
        name: item.name,
        size: item.size,
        price: item.price
      };
    });

    return cart
  }

  getUpdatedCart(sku) {
    const mutatedArray = this.context.data.cart.slice().reverse()
    const index = mutatedArray.findIndex(p => p.sku===sku)
    const removedArray = mutatedArray.splice(index, 1)
    return mutatedArray.reverse()
  }

  getCartTotal() {
    const cartItems = this.getCartItems()
    const total = Object.values(cartItems).reduce((a, b) => a + b.amount * b.price, 0)
    return total
  }

  handleCheckoutClick() {
    console.log("tapped checkout")
  }

  render() {
    return (
      <div className="cart-wrapper">
        <div className="cart-header">
          <h2>
            <FormattedMessage id="cart.title" />
          </h2>
          <Context.Consumer>
            {({ data, set }) => (
              <button className="close-button" onClick={() => set({ cartOpen: false })}><img src={cartClose} alt="Cart" width="30" /></button>
            )}
          </Context.Consumer>
        </div>

        <ul>
          { Object.keys(this.getCartItems()).length > 0 ? (Object.keys(this.getCartItems()).map( key =>
            <li key={key}>
              <div className="cart-item-header">
                <h5>{ this.getCartItems()[key].name }{this.getCartItems()[key].amount > 0 && <><span className="no-caps"> x </span> {this.getCartItems()[key].amount} </> }</h5>
                <Context.Consumer>
                  {({ data, set }) => (
                    <button className="remove-button" onClick={
                      () => {
                        set({ 
                          cart: this.getUpdatedCart(key)
                        })
                        firebase.analytics().logEvent(firebase.analytics.EventName.REMOVE_FROM_CART, getViewedItem(this.getCartItems()[key].name, this.getCartItems()[key].price, key))
                     }}><img src={minusIcon} alt="Remove" width="20" /></button>
                  )}
                </Context.Consumer>
              </div>
              <p>{this.props.intl.formatMessage({id: 'cart.size'}) + " " + this.getCartItems()[key].size }</p>
              <p>{ this.getCartItems()[key].price * this.getCartItems()[key].amount } kr</p>
              <div className="cart-divider"></div>
            </li>
          )) : (
            <p>
              <FormattedMessage id="cart.empty" />
            </p>
          )}
        </ul>
        { Object.keys(this.getCartItems()).length > 0 ? (
          <div className="cart-footer">
            <Context.Consumer>
              {({ data, set }) => (
                <div onClick={
                  () => set({ 
                    cartOpen: false
                  })
                }>
                    <Button color="light" text={this.props.intl.formatMessage({id: 'cart.checkout'})} link={this.props.intl.formatMessage({id: 'cart.checkout-link'})} />
                  </div>
              )}
            </Context.Consumer>
            <p>{ this.getCartTotal() } kr</p>
          </div>
        ) : null }
      </div>
    )
  }
}

Cart.contextType = Context;

export default injectIntl(Cart)
