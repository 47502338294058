import React from 'react'

import SEO from '../components/seo'
import LocaleManager from '../components/LocaleManager'
import { FormattedMessage } from 'react-intl'

const NotFoundPage = ({pageContext: { locale }}) => (
  <LocaleManager locale={locale}>
    <SEO title="404: Not found" />
    <div className="content extra-top">
      <div>
      <h3>
        <FormattedMessage id="404.not-found" />
      </h3>
      <p>
        <FormattedMessage id="404.not-found-body" />
      </p>
      </div>
    </div>
  </LocaleManager>
)

export default NotFoundPage
