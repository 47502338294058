import React, { Component } from 'react'
import { Link } from 'gatsby'

import './mobileMenu.scss'

class MobileMenu extends Component {
  render() {
    return (
      <div className="mobile-menu-wrapper">
        <ul>
            { this.props.menuLinks.map((link, i) =>
            <li onClick={this.props.toggleMenu} key={i}>
                <Link to={ link.link }>{ link.name }</Link>
            </li>
            )}
        </ul>
      </div>
    )
  }
}

export default MobileMenu