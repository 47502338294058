import React from "react"

export const defaultContextValue = {
  data: {
    cartOpen: false,
    cart: []
  },
  set: () => {},
}

export const StoreContext = React.createContext(defaultContextValue)
